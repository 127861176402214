import { defineStore, acceptHMRUpdate } from 'pinia'
import { getCustomAppearances as getCustomAppearancesFromDb, deleteCustomAppearance as deleteCustomAppearanceFromDb } from '@/services/databaseHelper'
import { getAxios, getSlowRequestAxios } from '@/services/axiosHelper'
import { CustomAppearance, Character, ProjectSettings } from '@/types'
import { useAppStore } from '@/store/app'
import { ref } from 'vue'
import loadingCharacter from '@/assets/loadingCharacter.gif'

export const defaultNameMapping: Record<string, string> = {
  '08-asi-mal': 'Jun',
  '08-asi-fem': 'Hana',
  '08-cau-mal': 'Liam',
  '08-cau-fem': 'Emma',
  '08-bla-mal': 'Malik',
  '08-bla-fem': 'Aaliyah',
  '08-lat-mal': 'Mateo',
  '08-lat-fem': 'Sofia',
  '18-asi-mal': 'Hiroshi',
  '18-asi-fem': 'Yuki',
  '18-cau-mal': 'Ethan',
  '18-cau-fem': 'Jessica',
  '18-bla-mal': 'Jamal',
  '18-bla-fem': 'Imani',
  '18-lat-mal': 'Diego',
  '18-lat-fem': 'Camila',
  '40-asi-mal': 'Wei',
  '40-asi-fem': 'Ling',
  '40-cau-mal': 'Daniel',
  '40-cau-fem': 'Nicole',
  '40-bla-mal': 'Andre',
  '40-bla-fem': 'Latoya',
  '40-lat-mal': 'Carlos',
  '40-lat-fem': 'Maria',
  '70-asi-mal': 'Hiroaki',
  '70-asi-fem': 'Etsuko',
  '70-cau-mal': 'Robert',
  '70-cau-fem': 'Dorothy',
  '70-bla-mal': 'Leroy',
  '70-bla-fem': 'Ernestine',
  '70-lat-mal': 'Jose',
  '70-lat-fem': 'Rosa'
}

export const reverseNameMapping = Object.fromEntries(Object.entries(defaultNameMapping).map(([key, value]) => [value, key]))

export const useAppearanceStore = defineStore('appearance', () => {
  const appStore = useAppStore()

  const customAppearances = ref<CustomAppearance[]>([])

  const getStyleAvatars = async () => {
    avatarUrls.value = await getCharacterImagesForStyle(appStore.currentProject.projectSettings.style)
  }

  const getCharacterImagesForStyle = async (projectStyle: ProjectSettings['style']) => {
    let style = projectStyle
    if (style === 'moodboard') {
      style = 'cinematic'
    }

    const axios = await getAxios()

    const response = await axios.get('/api/get-characters', { params: { style } })

    return response.data as { [key: string]: string }
  }

  const avatarUrls = ref<{ [key: string]: string }>({})

  const moodboardAvatarUrls = ref<{ [key: string]: string }>({})

  const updateMoodboardAvatars = async (names: string[], moodboardId: string) => {
    const axios = await getSlowRequestAxios()

    const response = await axios.post('/api/get-characters-moodboard', { names, moodboard_id: moodboardId })
    moodboardAvatarUrls.value = { ...moodboardAvatarUrls.value, ...response.data }
  }
  // deploy

  const getCustomAppearances = async (): Promise<void> => {
    const customAppearancesData = await getCustomAppearancesFromDb()
    customAppearances.value = customAppearancesData
  }

  const imageUrlFromAvatar = (name: string) => {
    if (appStore.currentProject?.moodboard_id) {
      if (moodboardAvatarUrls.value[name]) {
        return moodboardAvatarUrls.value[name]
      } else {
        updateMoodboardAvatars([name], appStore.currentProject.moodboard_id)
        return loadingCharacter
      }
    }

    const translatedName: string | undefined = defaultNameMapping[name]
    if (translatedName) {
      return avatarUrls.value[translatedName]
    }
    return avatarUrls.value[name]
  }

  const characterImageUrl = (character: Character) => {
    if (character.avatar !== null) {
      return imageUrlFromAvatar(character.avatar)
    } else {
      return character.image_url
    }
  }

  const deleteCustomAppearance = async (id: string) => {
    await deleteCustomAppearanceFromDb(id)
    customAppearances.value = customAppearances.value.filter(appearance => appearance.id !== id)
  }

  return {
    customAppearances,
    getCustomAppearances,
    getCharacterImagesForStyle,
    avatarUrls,
    moodboardAvatarUrls,
    updateMoodboardAvatars,
    getStyleAvatars,
    imageUrlFromAvatar,
    characterImageUrl,
    deleteCustomAppearance
  }
})

// eslint-disable-next-line
if ((import.meta as any).hot) {
  // eslint-disable-next-line
  (import.meta as any).hot.accept(acceptHMRUpdate(useAppearanceStore, (import.meta as any).hot)) // ts-ignore
}
