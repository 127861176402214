import type { VideoPayloadSVD, VideoPayloadRunway, VideoPayloadVeo2 } from '@/types' // adjust import path as needed

export enum VIDEO_MODELS {
  RUNWAY = 'runway',
  SVD = 'svd',
  VEO2 = 'veo2'
}

export const VIDEO_COSTS = {
  [VIDEO_MODELS.RUNWAY]: 25,
  [VIDEO_MODELS.SVD]: 5,
  [VIDEO_MODELS.VEO2]: 100
} as const

export const DEFAULT_VIDEO_DURATION = 5

export const SVD_DEFAULT_PAYLOAD: Omit<VideoPayloadSVD, 'sourceImage'> = {
  condAug: 0.02,
  fps: 15,
  steps: 10,
  motionBucketId: 50,
  type: VIDEO_MODELS.SVD
}

export const RUNWAY_DEFAULT_PAYLOAD: Omit<VideoPayloadRunway, 'sourceImage'> = {
  videoPrompt: '',
  videoDuration: DEFAULT_VIDEO_DURATION,
  type: VIDEO_MODELS.RUNWAY
}

export const VEO2_DEFAULT_PAYLOAD: Omit<VideoPayloadVeo2, 'sourceImage'> = {
  videoPrompt: '',
  type: VIDEO_MODELS.VEO2
}
