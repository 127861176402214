<template>
  <Dialog
    v-model:open="runwayUpsellModal"
    class="tw-w-[1000px] tw-border-none"
  >
    <DialogContent
      hide-close
      class="tw-min-w-[860px] tw-border-none tw-p-4"
    >
      <div v-if="thankYou">
        <div class="tw-w-full tw-flex tw-flex-col tw-items-center tw-gap-4">
          <h1 class="tw-text-2xl tw-font-bold">
            Thank you for upgrading!
          </h1>
          <p>Your subscription has been activated.</p>
          <Button
            @click="runwayUpsellModal = false"
          >
            Close and Start Creating
          </Button>
        </div>
      </div>
      <div v-else-if="paymentRejected">
        <div class="tw-w-full tw-flex tw-flex-col tw-gap-4">
          <h1>Payment failed</h1>
          <p>Unfortunately, your payment was unsuccessful.</p>
          <p>Please update your payment details.</p>
          <Button
            class="tw-ml-auto tw-bg-white tw-text-black tw-font-bold tw-mt-4"
            :loading="updatePaymentLoading"
            @click="redirectToCustomerPortal"
          >
            Update Payment Method
          </Button>
        </div>
      </div>
      <div
        v-else
        class="tw-w-full"
      >
        <img :src="RunwayLogo">
        <div class="tw-flex tw-flex-col tw-gap-4">
          <div class="tw-text-2xl tw-font-bold tw-pt-4 tw-px-4 tw-flex tw-items-end tw-gap-4">
            <span><span class="tw-line-through tw-text-gray-500 tw-text-xl">${{ price }}</span><span class="tw-text-neutral-100 tw-text-2xl tw-mx-2">${{ discountedPrice }}</span><span class="tw-text-sm tw-text-gray-500">/ month</span></span>
            <Button
              class="tw-ml-auto tw-bg-white tw-text-black tw-font-bold tw-mt-4"
              @click="confirmUpgrade"
            >
              {{ loading ? 'Activating...' : 'Activate Subscription' }}
            </Button>
            <Button
              :disabled="loading"
              class="tw-pl-4 tw-pr-4 !tw-bg-neutral-900 !tw-text-neutral-100 tw-font-bold tw-mt-4 hover:tw-bg-neutral-800"
              @click="continueTrial"
            >
              Continue Trial
            </Button>
          </div>
        </div>
      </div>
    </DialogContent>
  </Dialog>
</template>

<script setup lang="ts">
import { Dialog, DialogContent } from '@/shadcn/components/ui/dialog'
import { Button } from '@/shadcn/components/ui/button'
import { inject, Ref, computed, watch, ref } from 'vue'
import RunwayLogo from '@/assets/runway-upsell.svg'
import { useUserStore } from '@/store/user'
import tracking from '@/services/tracking'
import { getAxios } from '@/services/axiosHelper'

const runwayUpsellModal = inject<Ref<boolean>>('runwayUpsellModal')!
const userStore = useUserStore()
const loading = ref(false)
const thankYou = ref(false)
const paymentRejected = ref(false)
const updatePaymentLoading = ref(false)

const price = computed(() => {
  if (userStore.org?.tier === 'Unlimited + Video Add-on') {
    return '149'
  }
  if (userStore.org?.tier === 'Pro + Video Add-on') {
    return '59'
  }
  if (userStore.org?.tier === 'Essential + Video Add-on') {
    return '29'
  }
  return ''
})

const discountedPrice = computed(() => {
  if (userStore.org?.tier === 'Unlimited + Video Add-on') {
    return '125'
  }
  if (userStore.org?.tier === 'Pro + Video Add-on') {
    return '53'
  }
  if (userStore.org?.tier === 'Essential + Video Add-on') {
    return '26'
  }
  return ''
})

const continueTrial = () => {
  tracking.track('Runway Upsell Modal Continue Trial Clicked')
  runwayUpsellModal.value = false
}

watch(runwayUpsellModal, (value) => {
  if (value) {
    tracking.track('Runway Upsell Modal Opened')
  }
})

const confirmUpgrade = async () => {
  tracking.track('Runway Upsell Modal Confirm Upgrade Clicked')
  loading.value = true
  const axios = await getAxios()
  try {
    const response = await axios.post('/api/upgrade-to-paid')
    // wait for organization to be updated
    await new Promise(resolve => setTimeout(resolve, 5000))
    loading.value = false
    if (response && response.data.success === true) {
      thankYou.value = true
      tracking.track('Runway Upsell Modal Confirm Upgrade Success')
    } else {
      paymentRejected.value = true
      tracking.track('Runway Upsell Modal Confirm Upgrade Failed')
    }
  } catch (error) {
    console.error('Payment failed:', error)
    loading.value = false
    paymentRejected.value = true
  }
}

async function redirectToCustomerPortal () {
  updatePaymentLoading.value = true
  tracking.track('Runway Upsell Modal Update Payment Method Clicked')
  const axios = await getAxios()
  const result = await axios.post('/api/update-payment-method')
  window.location.assign(result.data.redirect_url)
}

</script>
