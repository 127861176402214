<script setup lang="ts">
import { cn } from '@/shadcn/lib/utils'
import { X } from 'lucide-vue-next'
import {
  DialogClose,
  DialogContent,
  type DialogContentEmits,
  type DialogContentProps,
  DialogOverlay,
  DialogPortal,
  useForwardPropsEmits
} from 'radix-vue'
import { computed, type HTMLAttributes } from 'vue'

type CustomProps = {
  hideClose?: boolean
}

const props = defineProps<DialogContentProps & CustomProps & { class?: HTMLAttributes['class'] } & CustomProps>()
const emits = defineEmits<DialogContentEmits>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <DialogPortal>
    <DialogOverlay
      class="tw-fixed tw-inset-0 tw-z-50 tw-bg-black/80 tw- data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0"
    />
    <DialogContent
      v-bind="forwarded"
      :class="
        cn(
          'tw-fixed tw-left-1/2 tw-top-1/2 tw-z-50 tw-grid tw-w-full tw-max-w-lg tw--translate-x-1/2 tw--translate-y-1/2 tw-gap-4 tw-border tw-bg-background tw-p-6 tw-shadow-lg tw-duration-200 data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0 data-[state=closed]:tw-zoom-out-95 data-[state=open]:tw-zoom-in-95 data-[state=closed]:tw-slide-out-to-left-1/2 data-[state=closed]:tw-slide-out-to-top-[48%] data-[state=open]:tw-slide-in-from-left-1/2 data-[state=open]:tw-slide-in-from-top-[48%] sm:tw-rounded-lg',
          props.class,
        )"
    >
      <slot />

      <DialogClose
        v-if="!props.hideClose"
        class="tw-absolute tw-right-4 tw-top-4 tw-rounded-sm tw-opacity-70 tw-ring-offset-background tw-transition-opacity hover:tw-opacity-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-ring focus:tw-ring-offset-2 disabled:tw-pointer-events-none data-[state=open]:tw-bg-accent data-[state=open]:tw-text-muted-foreground"
      >
        <X class="tw-w-4 tw-h-4" />
        <span class="tw-sr-only">Close</span>
      </DialogClose>
    </DialogContent>
  </DialogPortal>
</template>
